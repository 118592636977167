import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import { ArrowButton } from 'components/_universal/ArrowButton';
import { navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ROUTE_HOME } from 'shared/paths';
import { B3 } from 'styles/Typography.styled';
import { isMarketingCookiesDisabled } from 'utils/cookies';

export default () => {
    // google tag ads conversion + linkedin conversion
    return (
        <>
            {!isMarketingCookiesDisabled() && (
                <Helmet>
                    <script>
                        {`
                      window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
               gtag('event', 'conversion', {'send_to': 'AW-780446271/HZkvCP32148BEL_UkvQC'});                        
            `}
                    </script>
                    <script>
                        {`
                      _linkedin_partner_id = '3495441';
                              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                      `}
                    </script>

                    <script>
                        {`
                      (function(l) {
                          if (!l) {
                              window.lintrk = function(a, b) {
                                  window.lintrk.q.push([a, b]);
                              };
                              window.lintrk.q = [];
                          }
                          var s = document.getElementsByTagName('script')[0];
                          var b = document.createElement('script');
                          b.type = 'text/javascript';
                          b.async = true;
                          b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                          s.parentNode.insertBefore(b, s);
                      })(window.lintrk);
                      `}
                    </script>
                    <noscript>
                        {`
                          <img
                              height="1"
                              width="1"
                              style={{ display: 'none' }}
                              alt=""
                              src="https://px.ads.linkedin.com/collect/?pid=3495441&fmt=gif"
                          />
                      `}
                    </noscript>
                </Helmet>
            )}

            <CommonMargin>
                <Section
                    title="Thank you for filling up the form!"
                    isFirst
                    withBreadCrumbs
                >
                    <B3 mt={6}>
                        We won't make you wait more than 24h - 48 h from
                        receiving an answer from us.
                    </B3>
                    <B3 mb={8}>Keep an eye on your phone or mailbox.</B3>

                    <ArrowButton
                        onClick={() => navigate(ROUTE_HOME)}
                        text={'home page'}
                    />
                </Section>
                <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    alt=""
                    src="https://px.ads.linkedin.com/collect/?pid=3495441&fmt=gif"
                />
            </CommonMargin>
        </>
    );
};
